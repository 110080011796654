// Login
.login-page, .register-page {
    
		 background: url(https://cdn.navkonzept.com/brand/rel/dist/img/AddSecure-Wallpaper-1920x1080-LoginBG.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: transparent;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='(https://cdn.navkonzept.com/brand/rel/dist/img/AddSecure-Wallpaper-1920x1080-LoginBG.jpg', sizingMethod='scale');
    -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='(https://cdn.navkonzept.com/brand/rel/dist/img/AddSecure-Wallpaper-1920x1080-LoginBG.jpg', sizingMethod='scale')";
		
		-webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: $gray-100;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100vh;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}


// Link
a {
    color: $cyan;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: $blue;
    text-decoration: none;
}


// Loading Circle
// set in _bootstrap-variables-addsecure.scss
.loading-circle-full {
	position: fixed;
	height: 100%;
	width: 100%;
	background: url($loader-gif-base64) no-repeat center center #fff;
	opacity: 0.7;
	z-index: 2000;
}

.loading-circle {    
	min-height:203px;
	padding: 5px 0;
	height: 100%;
	width: 100%;
	background: url($loader-gif-base64) no-repeat center center #fff;
	opacity: 0.7;
	z-index: 2000;
}

// Cookie holder
.cookie-holder {	
    
		position: fixed;
    overflow: hidden;
    box-sizing: border-box;   
    display: table;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    z-index: 1040;
    left: 0;
    right: 0;
    bottom: 0;
    -ms-flex-align: center;
    align-items: center;
    padding: 1em 1.8em;
    width: 100%;
    -ms-flex-direction: row;
    flex-direction: row;    
    background-color: $blue;
}
.cookie-text{color: $gray-100;}


// iCheck Bootstrap - Checkbox & Radio Inputs
.icheck-primary label:not(.form-check-label):not(.custom-file-label) {
    font-weight: normal;
}
.icheck-danger label:not(.form-check-label):not(.custom-file-label) {
    font-weight: normal;
}
.icheck-success label:not(.form-check-label):not(.custom-file-label) {
    font-weight: normal;
}


/*
// font 
@font-face {
  font-family: "Asap";
  src: url('https://fonts.googleapis.com/css2?family=Asap&display=swap');        
}
@font-face {
  font-family: "Open Sans";
  src: url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');        
}
$font-family-sans-serif:      "Asap", "Open Sans", "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;
*/


// Button default
.btn-default {
    background-color: transparent;    
    border-color: transparent;
    color: $blue;
}
.btn-default:hover {
    color: $blue;
    text-decoration: none;
		background-color: $cyan;
}
.btn-default.active {
    color: $white;
    text-decoration: none;
		background-color: $blue;
}


// Button primary
.btn-primary.focus, .btn-primary:focus {
    background-color: $blue;
    border-color: $blue;   
}
.btn-primary.hover, .btn-primary:hover {
    background-color: $blue;
    border-color: $blue;   
}


// Button Group Horizontal addstyle

.btn-group.addstyle > .btn:hover{
	z-index: 1;
	color: $white;
	background-color: $cyan;	
}
.btn-group.addstyle > .btn.start{
	color: $white;
  border-color: $blue;
	background-color: $blue;
	cursor: default;
}
.btn-group.addstyle > .btn.start:hover{
	color: $white;
  border-color: $blue;
	background-color: $blue;
	cursor: default;
}
.btn-group.addstyle > .btn{
	color: $blue;
  border-color: $blue; /*lighten($blue, 15%);	*/
	background-color: $white;	
}
.btn-group.addstyle > .btn.active{
	color: $blue;  
	background-color: $cyan;	
}
.btn-group.addstyle > .btn.active:hover{
	color: $white;
	background-color: $cyan;	
}
.btn-group.addstyle > .btn.disabled{
	color: $gray-500;
	border-color: $blue;
	background-color: $white;	
}


// Button Group Vertikal addstyle
.btn-group-vertical.addstyle > .btn.start{
	color: $white;
  border-color: $blue;
	background-color: $blue;
	cursor: default;
}
.btn-group-vertical.addstyle > .btn.start:hover{
	color: $white;
  border-color: $blue;
	background-color: $blue;
	cursor: default;
}
.btn-group-vertical.addstyle > .btn{
	color: $blue;
  border-color: $blue; /*lighten($blue, 15%);	*/
	background-color: $white;	
}
.btn-group-vertical.addstyle > .btn.active{
	color: $blue;  
	background-color: $cyan;	
}
.btn-group-vertical.addstyle > .btn.active:hover{
	color: $white;
	background-color: $cyan;	
}
.btn-group-vertical.addstyle > .btn:hover{
	color: $white;
	background-color: $cyan;	
}
.btn-group-vertical.addstyle > .btn.disabled{
	color: $gray-500;
	border-color: $blue;
	background-color: $white;	
}

// Button Group Toggle
.btn-group.btn-group-toggle.addstyle > .btn:hover{
	color: $white;
	background-color: $cyan;	
}
.btn-group.btn-group-toggle.addstyle > .btn.start{
	color: $white;
  border-color: $blue;
	background-color: $blue;
	cursor: default;
}
.btn-group.btn-group-toggle.addstyle > .btn.start:hover{
	color: $white;
  border-color: $blue;
	background-color: $blue;
	cursor: default;
}
.btn-group.btn-group-toggle.addstyle > .btn{
	font-weight: 400;
	color: $blue;
  border-color: $blue; /*lighten($blue, 15%);	*/
	background-color: $white;	
}
.btn-group.btn-group-toggle.addstyle > .btn.active{
	color: $blue;  
	background-color: $cyan;	
}
.btn-group.btn-group-toggle.addstyle > .btn.active:hover{
	color: $white;
	background-color: $cyan;	
}
.btn-group.btn-group-toggle.addstyle > .btn.disabled{
	color: $gray-500;
	border-color: $blue;
	background-color: $white;	
}






// Button Group addstyle Dropdown
.btn-group.addstyle > .dropdown-item:focus, .dropdown-item:hover {
    color: $blue;
    text-decoration: none;
    background-color: $cyan;
}	
.btn-group.addstyle > .dropdown-item.active, .dropdown-item:active {
    color: $blue;
    text-decoration: none;
    background-color: $cyan;
}


.btn,.btn-sm{
// @include border-radius(0);
}

// content-header
.content-header {
    padding: 15px .5rem;
	background-color: $white;
}

// toasts
.toast-header {
    color: $blue;
	.close{
		color: $blue;
	}
}

//Calendar datetimepicker
.bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover {
    background-color: $blue;
    color: $white;
    text-shadow: 0 0 0 0;
}
.bootstrap-datetimepicker-widget table td.day:hover, .bootstrap-datetimepicker-widget table td.hour:hover, .bootstrap-datetimepicker-widget table td.minute:hover, .bootstrap-datetimepicker-widget table td.second:hover {
    background: $cyan;
    cursor: pointer;
}
.bootstrap-datetimepicker-widget table td.today:before {
    border-bottom-color: $blue;    
}
.bootstrap-datetimepicker-widget table td.today {
  border: $blue 2px solid;
			
}
.bootstrap-datetimepicker-widget table thead tr:first-child th {
    color: $blue;
}
.bootstrap-datetimepicker-widget table th {
    color: $blue;
}
.bootstrap-datetimepicker-widget table td span.active {
    background-color: $blue;
}



// Date range

.daterangepicker td.in-range {
    background-color: $cyan;    
}
.daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: $blue;   
}
.daterangepicker td.available:hover, .daterangepicker th.available:hover {
    background-color: $cyan;    
}
.daterangepicker .ranges li:hover {
    background-color: $cyan;
}
.daterangepicker .ranges li.active {
    background-color: $blue;   
}




// Default navbar
.navbar{  
 
  .navbar-nav{
		color:$white;
	}

	padding: 0 .5rem;	

	// Mixins: Navbar
	background-color: $blue;

	&.navbar-white{
		background-color: $white;
	}

	.navbar-nav > li > a {
		color: $blue;
	}

	button.btn.btn-navbar{
		color: $white;
	}
			
	.navbar-nav > li > a:focus,
	.navbar-nav .open > a,	  
	.navbar-nav .open > a:focus {
		background-color: $blue;
		color: $gray-400;
	}
	
	.navbar-nav > li > a:hover,
	.navbar-nav .open > a:hover{
		background-color: $cyan;
		color: $white;
	}
	
	.navbar-nav > li > a:active,
	.navbar-nav > .active > a {
		background-color: $cyan;
		color: $white;
	}

	// Add color to the sidebar toggle button
	.sidebar-toggle {
		color: $white;

		&:hover,
		&:focus {
			background-color: $blue;
			color: $cyan;
		}
	}

	&.navbar-dark .navbar-text {
    color: rgba(255,255,255,1);
	}
	&.navbar-dark .navbar-nav .nav-link{
    color: rgba(255,255,255,1);
	}
  // Mixins: Navbar end
}

// sidebar 
.sidebar-collapse .main-sidebar, .sidebar-collapse .main-sidebar::before {
    background-color: #fff;    
}
.main-sidebar, .main-sidebar::before {
		background-color: #fff;    
}

// brand-link
.brand-link {
	//padding: 0.33rem;
	background-color: $blue;
	color: $white;	
}

.brand-link .brand-image-xs {    
    height: 40px;
		max-height: 40px;
}
.brand-link .brand-image-xl {		
		max-height: 40px;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: $white;	
    background-color: $blue;
}

.slider-handle{	
			background-color: $blue;
			background-image: linear-gradient(to bottom, $blue, $blue);
}

.nav-treeview>.nav-item>.nav-link.active, [class*=sidebar-light-] .nav-treeview>.nav-item>.nav-link.active:hover {
    @include border-radius(0px);
		background-color: $white;
    box-shadow: inset 0 -6px 0 0 $orange;
		color: $orange;
}

.nav-treeview>.nav-item>.nav-link {
    color: $blue;
}

.sidebar a {
    color: $blue; 
}
.sidebar a:hover {
    background-color: $white;
}

.badge-info {
    color: $white;
    background-color:  $blue;
}


// breadcrumb-item (_layout.scss)

.breadcrumb-item {
	&.active,
	+ .breadcrumb-item::before {
		color: $orange;
	}
}

// Datatable (_table.scss)
.table.dataTable {    
	thead th {
  //border-bottom-color: $gray-200;
  //background-color: $blue;
  //color: $white;     

	color: $white;
	background-color: $blue;
	border-top: $blue 2px solid;
	border-bottom: $blue 2px solid;
	padding-left: 5px;
	border-right: 1px $white dashed;
	line-height: 1.0;
    }
    th,
    td {
      border-top-color: $gray-200;
      line-height: 1.0;
    }
 }

 // sorting ASC
table.dataTable>thead .sorting_asc:before, table.dataTable>thead .sorting_desc:after {
	opacity: 1;
	color: $orange;
}
table.dataTable>thead .sorting:before, table.dataTable>thead .sorting:after, table.dataTable>thead .sorting_asc:before, table.dataTable>thead .sorting_asc:after, table.dataTable>thead .sorting_desc:before, table.dataTable>thead .sorting_desc:after, table.dataTable>thead .sorting_asc_disabled:before, table.dataTable>thead .sorting_asc_disabled:after, table.dataTable>thead .sorting_desc_disabled:before, table.dataTable>thead .sorting_desc_disabled:after {
	opacity: 1;
}

// sorting desc
table.dataTable>thead .sorting_asc:before, table.dataTable>thead .sorting_desc:after {
	opacity: 1;
	color: $orange;
}
table.dataTable>thead .sorting:before, table.dataTable>thead .sorting:after, table.dataTable>thead .sorting_asc:before, table.dataTable>thead .sorting_asc:after, table.dataTable>thead .sorting_desc:before, table.dataTable>thead .sorting_desc:after, table.dataTable>thead .sorting_asc_disabled:before, table.dataTable>thead .sorting_asc_disabled:after, table.dataTable>thead .sorting_desc_disabled:before, table.dataTable>thead .sorting_desc_disabled:after {
	opacity: 1;
}

// pagination (page-link)
.page-link {
	color: $blue;    
	border: 1px solid $gray-400;
}
.page-item.active .page-link {    
	color: $white;
	background-color: $blue;
	border-color: $blue;
}
.page-item.disabled .page-link {
	color: $white;
	background-color: $gray-400;
	border-color: $gray-400;
}

.page-link:hover {
	color: $white;
	background-color: $blue;
	border-color: $blue;
}

// slider
.irs--flat .irs-from, .irs--flat .irs-to, .irs--flat .irs-single {
	background-color: $blue;    
}
.irs--flat .irs-from:before, .irs--flat .irs-to:before, .irs--flat .irs-single:before {
	border-top-color: $blue;
}
.irs--flat .irs-bar {
	top: 25px;
	height: 12px;
	background-color: $blue;
}
.irs--flat .irs-handle>i:first-child {    
	background-color: $blue;
}



//typography
blockquote {    
    border-left: .7rem solid $orange;   
}
blockquote.quote-secondary {    
		border-left: .7rem solid $blue;  
}

// formular
.input-group-text {    
    color: $white;   
    background-color: $blue;
		border: 1px solid $blue;
}
.form-control {    
    border: 1px solid $gray-400;    
}

.select2-container--default:hover {
    background-color: $cyan;
}
.select2-container--default .select2-results__option--highlighted[aria-selected], .select2-container--default .select2-results__option--highlighted[aria-selected]:hover {
    background-color: $blue;
}
//.select2-container--default.select2-container--focus .select2-selection--single {
//    border-color: $orange;
//}